import { _axios } from '@/plugins/axios'

export const apiDisbursePage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/disburse/page`,
		method: method,
		data: dataJson
	})
}
export const apiDisburseTemplates = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/disburse/templates`,
		method: method,
		data: dataJson
	})
}
export const apiSmsSend = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/sms/send`,
		method: method,
		data: dataJson
	})
}
export const apiSmsLog = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/sms/log`,
		method: method,
		data: dataJson
	})
}
export const apiDemandLetterPage = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/demandLetter/page`,
		method: method,
		data: dataJson
	})
}
export const apiDemandLetterEmail = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/demandLetter/email`,
		method: method,
		data: dataJson
	})
}
export const apiDemandLetterParam = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/demandLetter/param`,
		method: method,
		data: dataJson
	})
}
export const apiDemandLetterUpload = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/demandLetter/upload`,
		method: method,
		data: dataJson
	})
}
export const apiSendEmail = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/demandLetter/email/send`,
		method: method,
		data: dataJson
	})
}
export const apiRecordSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/overdue/record/save`,
		method: method,
		data: dataJson
	})
}
export const apiRecordList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/overdue/record/list`,
		method: method,
		data: dataJson
	})
}
export const apiCommentSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/overdue/comment/save`,
		method: method,
		data: dataJson
	})
}
export const apiCommentList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/overdue/comment/list`,
		method: method,
		data: dataJson
	})
}
export const apiEarlySettlementForm = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/disburse/earlySettlementForm`,
		method: method,
		data: dataJson
	})
}
export const apiRepaymentSchedule = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/disburse/repaymentSchedule`,
		method: method,
		data: dataJson
	})
}
export const apiDisburseData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/disburse/data`,
		method: method,
		data: dataJson
	})
}
export const apiDemandLetterData = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/collections/demandLetter/data`,
		method: method,
		data: dataJson
	})
}
export const apiFollowCollectors = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/follow/collectors/store`,
		method: method,
		data: dataJson
	})
}
export const apiLoanCollectors = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/follow/collectors/loan`,
		method: method,
		data: dataJson
	})
}
export const apiAssignFollowCollectors = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/collections/follow/assign`,
		method: method,
		data: dataJson
	})
}
