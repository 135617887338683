<template>
	<div>
		<search-list-table
			ref="searchListTable"
			:columns="columns"
			:formSearchList="formSearchList"
			:initDataSource="initDataSource"
			:upfrontFeesRowSelection="upfrontFeesRowSelection"
		>
			<template slot="btn">
				<a-button type="primary" @click="exportSheet">
					{{ $t('page.download') }}
				</a-button>
				<a-button type="primary" style="margin-left: 20px" @click="assignModal"> Assign </a-button>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
				<a-divider type="vertical" />
				<a @click="ShowModal(columnData)">Issue Reminder/Letter</a>
				<!-- <a-divider type="vertical" />
				<a @click="getApiSmsLog(columnData.loanId)">Record</a> -->
			</template>
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<a @click="getSmsLog(columnData)">Details</a> <a-tag color="blue" v-if="todaySent(columnData)">Sent</a-tag>
			</template>
		</search-list-table>
		<a-modal class="overpayment" v-model="createModalShow" title="" :destroyOnClose="true" width="600px" @ok="sendSms">
			<a-form-item label="Choose a sample" class="send">
				<a-select v-model="templatesValue" @change="getapiDisburseTemplates">
					<a-select-option :value="item.value" v-for="item in templatesList" :key="item.value">{{ item.label }}</a-select-option>
				</a-select>
			</a-form-item>
			<a-textarea v-model="templatesData" :auto-size="{ minRows: 10, maxRows: 25 }" />
			<a-form-item label="Send to" class="send">
				<a-input addonBefore="0" :value="phoneNumber" placeholder="Input number" :max-length="9" @change="onChange" @blur="onBlur" />
			</a-form-item>
		</a-modal>
		<a-modal class="overpayment" v-model="recordModalShow" :footer="false" title="Records" :destroyOnClose="true" width="800px">
			<a-table
				v-if="recordData"
				style="font-size: 12px"
				:columns="recordDataColumns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="recordData"
				:pagination="pagination"
				bordered
			></a-table>
		</a-modal>
		<a-modal class="overpayment" v-model="assignModalShow" title="Assign" :destroyOnClose="true" width="600px" @ok="assignFollowCollector">
			<a-form-item label="Choose a collector" class="send">
				<a-select v-model="followerNameSelect" mode="multiple">
					<a-select-option :value="item.userId" v-for="item in followerNameList" :key="item.userId">{{ item.username }}</a-select-option>
				</a-select>
			</a-form-item>
			<p
				>Attention: Every collector can only see the cases of his own branch.If you don't choose the branch , then when you choose all the
				collectors,the cases will assigned to the collector of his own branch</p
			>
		</a-modal>
	</div>
</template>
<script>
import {
	apiDisbursePage,
	apiDisburseTemplates,
	apiSmsSend,
	apiSmsLog,
	apiDisburseData,
	apiLoanCollectors,
	apiAssignFollowCollectors,
	apiFollowCollectors
} from '@/api/collections'
import { apiBranchList } from '@/api/branch'
import moment from 'moment'
export default {
	data() {
		return {
			branchOfficeList: [],
			createModalShow: false,
			templatesList: [
				{
					label: 'Payment Reminder before Due Date',
					value: 'notDue'
				},
				{
					label: 'Payment Reminder on Due Date',
					value: 'due'
				}
			],
			templatesValue: 'notDue',
			templatesData: '',
			phoneNumber: '',
			recordModalShow: false,
			recordData: [],
			pagination: {
				total: 0,
				showTotal: (total) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100']
			},
			assignModalShow: false,
			followerNameSelect: [],
			followerNameList: [],
			upfrontFeesSelectRow: [],
			upfrontFeesRowSelection: {
				fixed: true,
				columnWidth: 50,
				selectedRowKeys: [],
				onChange: (k, r) => {
					this.upfrontFeesRowSelection.selectedRowKeys = k
					this.upfrontFeesSelectRow = r
				}
			},
			officeId: '',
			followerList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'DPD',
					type: 'select',
					validateField: ['dueDays'],
					selectOption: [
						{
							DPDName: 'DPD-7',
							value: '-7'
						},
						{
							DPDName: 'DPD-1',
							value: '-1'
						},
						{
							DPDName: 'DPD-0',
							value: '-0'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'DPDName'
					}
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.applyInfo.applicationLabel.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					},
					selectChange: (value) => {
						this.getApiFollowCollectors(value !== '' ? [value] : [])
					}
				},
				{
					label: 'table.applyInfo.cloums.caseStatus',
					type: 'select',
					validateField: ['statuses'],
					selectOption: ['1001', '1002', '1003', '1004'],
					prefix: 'table.finance.loanStatusMap'
				},
				{
					label: 'table.finance.cloum.defaultDate',
					type: 'range-picker',
					validateField: ['dueDate', { initialValue: [] }]
				},
				{
					label: 'table.finance.cloumRepayment.shouldDate',
					type: 'range-picker',
					validateField: ['scheduleDate', { initialValue: [] }]
				},
				{
					label: 'DPD',
					type: 'scope-input-number',
					validateField: [['dueDaysBegin'], ['dueDaysEnd']]
				},
				{
					label: 'Due Date',
					type: 'scope-input-number',
					validateField: [['dueDayBegin'], ['dueDayEnd']]
				},
				{
					label: 'Assigned',
					type: 'select',
					validateField: ['isAssign'],
					selectOption: [
						{
							text: 'No',
							value: '0'
						},
						{
							text: 'Yes',
							value: '1'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'text'
					}
				},
				{
					label: 'Follower',
					type: 'select',
					validateField: ['follower'],
					selectOption: this.followerList,
					dataPreType: {
						value: 'userId',
						text: 'username'
					}
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'fullName',
					align: 'center'
				},
				{
					// 手机号
					title: this.$t('table.userFrom.columns.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					customRender: (v, o) => (v ? v.join('/') : '')
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					align: 'center',
					dataIndex: 'carInfos',
					customRender: (v, o) =>
						v
							? v
									.map((item) => item.registrationNumber)
									.filter(Boolean)
									.join('/')
							: ''
				},
				{
					title: 'DPD',
					align: 'center',
					dataIndex: 'dpd'
				},
				{
					title: this.$t('table.finance.cloumRepayment.should'),
					align: 'center',
					dataIndex: 'scheduleRepayment',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.finance.cloumRepayment.shouldDate'),
					align: 'center',
					dataIndex: 'scheduleDate'
				},
				{
					title: 'Sending History',
					scopedSlots: { customRender: 'scopedSlots' },
					align: 'center'
				},
				{
					title: 'Follower',
					dataIndex: 'followerName',
					align: 'center',
					customRender: (v, o) => (v ? v : '-')
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 290,
					fixed: 'right'
				}
			]
		},
		recordDataColumns() {
			return [
				{
					title: 'Send Time',
					dataIndex: 'sendTime',
					align: 'center'
				},
				{
					title: 'Message',
					dataIndex: 'message',
					align: 'center'
				},
				{
					title: 'Operator',
					align: 'center',
					dataIndex: 'username'
				}
			]
		}
	},
	methods: {
		moment,
		initDataSource(initParams) {
			this.officeId = initParams.officeId
			return apiDisbursePage({ ...initParams })
		},
		getapiDisburseTemplates(templateCode) {
			apiDisburseTemplates({
				loanId: this.loanId,
				templateCode: templateCode
			}).then((res) => {
				this.templatesData = res
			})
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		},
		onChange(e) {
			const { value } = e.target
			const reg = /^-?[0-9]*(\.[0-9]*)?$/
			if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
				this.phoneNumber = value
			}
		},
		onBlur() {
			const { phoneNumber, onChange } = this
			if (phoneNumber.charAt(phoneNumber.length - 1) === '.' || phoneNumber === '-') {
				onChange({ value: phoneNumber.slice(0, -1) })
			}
		},
		async assignModal() {
			if (this.upfrontFeesSelectRow.length === 0) {
				this.$message.error('Please choose cases first')
				return false
			}
			this.followerNameSelect = []
			this.assignModalShow = true
			this.followerNameList = await apiLoanCollectors(this.upfrontFeesSelectRow.map((item) => item.loanId))
		},
		ShowModal(columnData) {
			if (columnData.dpd === 0) {
				this.templatesValue = 'due'
			} else {
				this.templatesValue = 'notDue'
			}
			this.loanId = columnData.loanId
			this.phoneNumber = columnData.mobiles[0]
			this.createModalShow = true
			this.getapiDisburseTemplates(this.templatesValue)
		},
		assignFollowCollector() {
			if (this.followerNameSelect.length === 0) {
				this.$message.error('Please choose a collector')
				return false
			}
			apiAssignFollowCollectors({
				loanIds: this.upfrontFeesSelectRow.map((item) => item.loanId), //案件id
				userIds: this.followerNameSelect //催收员id
			})
				.then(() => {
					this.$message.success('Success')
					this.assignModalShow = false
					this.upfrontFeesRowSelection.selectedRowKeys = []
					this.upfrontFeesSelectRow = []
					this.followerNameSelect = []
					this.$refs.searchListTable.initTable(1)
				})
				.catch((err) => {})
		},
		getApiFollowCollectors(officeId) {
			return apiFollowCollectors(officeId).then((res) => {
				this.followerList = res
			})
		},
		sendSms() {
			if (!this.phoneNumber) {
				this.$message.error('Phone Number error!')
				return false
			}
			if (!this.templatesData) {
				this.$message.error('Sms error!')
				return false
			}
			this.$confirm({
				title: '',
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiSmsSend({
						loanId: this.loanId,
						mobile: '0' + this.phoneNumber,
						content: this.templatesData,
						remindType: this.templatesValue === 'due' ? 'due_reminder' : 'undue_reminder'
					})
						.then(() => {
							this.$message.success('Success')
							this.createModalShow = false
							this.$refs.searchListTable.initTable(1)
						})
						.catch((err) => {})
				},
				onCancel() {}
			})
		},
		getApiSmsLog(loanId) {
			apiSmsLog({
				loanId: loanId
			}).then((result) => {
				this.recordModalShow = true
				this.recordData = result ? result : []
			})
		},
		getSmsLog(columnData) {
			this.recordModalShow = true
			this.recordData = columnData.smsLogs ? columnData.smsLogs : []
		},
		todaySent(columnData) {
			return moment(moment(columnData.smsLogs && columnData.smsLogs[0].sendTime).format('YYYY-MM-DD')).isSame(moment(new Date()).format('YYYY-MM-DD'))
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			searchForm.scheduleDateBegin = searchForm.scheduleDate[0] && searchForm.scheduleDate[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.scheduleDateEnd = searchForm.scheduleDate[1] && searchForm.scheduleDate[1].format('YYYY-MM-DD') + ' 00:00:00'
			delete searchForm.scheduleDate
			apiDisburseData(searchForm).then((res) => {
				let str = `Case ID,Client Name,Mobile No.,Registration No.,DPD,Scheduled Repayment,Scheduled Date`
				res.forEach((item) => {
					const registrationNumbers = item.carInfos ? item.carInfos.map((reg) => reg.registrationNumber) : null
					str += `\r\n${item.casePrefix}-${item.caseId},${item.fullName},${item.mobiles ? item.mobiles.join('/') : '-'},${
						registrationNumbers ? registrationNumbers.join('/') : '-'
					},${item.dpd},${item.scheduleRepayment},${item.scheduleDate}`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Loans Disbursed.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
			apiFollowCollectors([]).then((res) => {
				this.followerList = res
			})
		})
	}
}
</script>
<style lang="less">
.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
